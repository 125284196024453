import React, { useRef } from "react";
import { toast } from "react-toastify";
import emailjs from "@emailjs/browser";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane, faTimes } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";

const ContactModal = (props) => {
  const form = useRef();
  const navigate = useNavigate();
  const { productSlug } = useParams();

  const sendEmail = async (e) => {
    if (form.current.phone.value.toString().length > 9) {
      const success = await handleSaveCustomer();
      if (success) {
        navigate("/dang-ky-thanh-cong");
        toast(`Đăng ký thành công`, {
          type: "success",
          autoClose: 1000,
          style: { marginTop: "50px" },
        });
      } else {
        toast(`Đăng ký thất bại`, {
          type: "error",
          autoClose: 1000,
          style: { marginTop: "50px" },
        });
      }
      formClose();
    } else {
      toast(`Vui lòng nhập số điện thoại`, {
        type: "error",
        autoClose: 1000,
        style: { marginTop: "50px" },
      });
    }
  };

  const handleSaveCustomer = async () => {
    const fullName = form.current.user_name.value.toString();
    const phone = form.current.phone.value.toString();
    const message = form.current.message.value.toString();

    const data = {
      fullName: fullName,
      phone: phone,
      message: message,
      slug: productSlug,
    };

    try {
      const res = await axios.post(`/customer/create`, data);
      if (res.data.errCode === 0) {
        return true;
      }
    } catch (error) {
      toast(`Có lỗi xảy ra`, {
        type: "error",
        autoClose: 1000,
        // style: { marginTop: "50px" },
      });
    }
    return false;
  };

  const formClose = () => {
    const { onHide } = { ...props };
    return onHide();
  };

  return (
    <Modal
      show={props.show}
      onHide={formClose}
      backdrop="static"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton className="fw-bold">
        Liên hệ với chúng tôi
      </Modal.Header>
      <Modal.Body>
        <Form ref={form} onSubmit={() => sendEmail()}>
          <Row>
            <p className="text-muted text-start">
              Hãy để lại thông tin. Kafood sẽ liên hệ với bạn trong thời gian
              sớm nhất!
            </p>
            <Col>
              <Form.Floating className="mb-3">
                <Form.Control
                  type="text"
                  name="user_name"
                  placeholder="Tên của bạn"
                  required
                />
                <Form.Label>
                  Tên của bạn<i className="text-danger">*</i>
                </Form.Label>
                <div className="invalid-feedback"></div>
              </Form.Floating>
            </Col>
            <Col>
              <Form.Floating className="mb-3">
                <Form.Control
                  type="text"
                  name="phone"
                  placeholder="Số điện thoại"
                  required
                />
                <Form.Label>
                  Số điện thoại<i className="text-danger">*</i>
                </Form.Label>
                <div className="invalid-feedback"></div>
              </Form.Floating>
            </Col>
          </Row>

          <Form.Floating className="mb-3">
            <Form.Control type="text" placeholder="Lời nhắn" name="message" />
            <Form.Label>Lời nhắn</Form.Label>
            <div className="invalid-feedback"></div>
          </Form.Floating>
        </Form>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={() => formClose()}>
          <FontAwesomeIcon icon={faTimes} /> Đóng
        </Button>
        <Button type="submit" variant="success" onClick={() => sendEmail()}>
          <FontAwesomeIcon icon={faPaperPlane} /> Gửi
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ContactModal;
