import axios from "axios";
import React, { useEffect, useState } from "react";
import { Breadcrumb, Col, Row, Spinner } from "react-bootstrap";
import { useParams, NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import momentFormat from "../../other/momentFormat";
// import DocumentMeta from 'react-document-meta';
import "./blog.scss";
import ContactForm from "../home/ContactForm";
import HeadMeta from "../../other/HeadMeta";

const BlogDetail = () => {
  const { productSlug } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    getPostBySlug();
  }, [window.location.pathname]);

  const [post, setPost] = useState({
    topicId: "",
    topicSlug: "",
    topicNameVi: "",
    authId: "",
    authName: "",
    slug: "",
    keyword: "",
    image: "",
    titleVi: "",
    // titleEn: '',
    descVi: "",
    // descEn: '',
    contentVi: "",
    // contentEn: '',
    statusId: "",
    createdAt: "",
  });

  const getPostBySlug = async () => {
    setIsLoading(true);
    await axios
      .get(`/getPostBySlug/${productSlug}`)
      .then((res) => {
        if (res.data.errCode === 0) {
          let postData = res.data.post;

          setPost({
            topicId: postData.topicId,
            topicSlug: postData.topicSlug,
            topicNameVi: postData.topicNameVi,
            authId: postData.authId,
            authName: postData.authName,
            slug: postData.slug,
            keyword: postData.keyword,
            image: postData.image,
            titleVi: postData.titleVi,
            descVi: postData.descVi,
            contentVi: postData.contentVi,
            statusId: postData.statusId,
            createdAt: postData.createdAt,
          });
        } else {
          toast(res.data.message, { type: "error" });
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        toast(error.message, { type: "error" });
      });
    // }
  };

  const meta = {
    title: post.titleVi,
    description: post.descVi,
    keywords: post.keyword || "",
    og_image: post.image || "",
    og_image_type: "image/*",
    // fb_app_id: '',
  };

  if (isLoading) {
    return (
      <div className="detail_bg" id="page_detail_blog">
        <div className="text-center mt-2">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">
              Hệ thống đang tải vui lòng chờ...
            </span>
          </Spinner>
        </div>
      </div>
    );
  }

  return (
    <div className="detail_bg" id="page_detail_blog">
      <Row className="m-0">
        <Col md={{ span: 8, offset: 2 }}>
          <div className="container px-lg-5 pb-md-3 bg-white">
            <Breadcrumb className="my-3">
              <Breadcrumb.Item linkAs={NavLink} linkProps={{ to: "/" }}>
                Trang chủ
              </Breadcrumb.Item>
              {post.topicSlug != "chinh-sach" ? (
                <Breadcrumb.Item
                  linkAs={NavLink}
                  linkProps={{ to: "/goc-chia-se" }}
                >
                  Góc chia sẻ
                </Breadcrumb.Item>
              ) : null}
              {post.topicSlug != "chinh-sach" ? (
                <Breadcrumb.Item
                  linkAs={NavLink}
                  linkProps={{ to: "/chu-de/" + post.topicSlug }}
                >
                  {post.topicNameVi}
                </Breadcrumb.Item>
              ) : null}
              <Breadcrumb.Item
                linkAs={NavLink}
                linkProps={{
                  to:
                    post.topicSlug != "chinh-sach"
                      ? "/bai-viet/" + post.slug
                      : "/chinh-sach/" + post.slug,
                }}
              >
                {post.titleVi}
              </Breadcrumb.Item>
            </Breadcrumb>

            <h1 className="text-center">{post.titleVi}</h1>

            <i>
              {post.authName} -{" "}
              {momentFormat(post.createdAt, "dddd, Do MMMM YYYY")}
            </i>

            <p className="blog-desc">{post.descVi}</p>

            <div
              className="blog-content"
              dangerouslySetInnerHTML={{ __html: post.contentVi }}
            ></div>
            {post.topicId != 5 && (
              <>
                <p className="text-gradient text-center">
                  Chi phí áp dụng phần mềm kafood vào quản lý mầm non chỉ từ
                  150k/tháng. Đăng ký ngay!
                </p>
                <ContactForm slug={productSlug} />
              </>
            )}
          </div>
        </Col>
      </Row>

      <HeadMeta {...meta} />
    </div>
  );
};

export default BlogDetail;
