import React, { useState, useRef, useEffect } from "react";
import { Button, Col, Form, Row, InputGroup } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import emailjs from "@emailjs/browser";
import Accordion from "react-bootstrap/Accordion";
import Carousel from "react-bootstrap/Carousel";
import { faCircleDot } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Cost from "./Cost";
import "./home.scss";
import HeadMeta from "../../other/HeadMeta";
import ContactModal from "./ContactModal";
import FunctionHome from "./FunctionHome";
import axios from "axios";
// import home1 from "assets/user/images/product/detail/TInh khau phan an.jfif";
function HomeIndex() {
  const { slug } = useParams();
  const navigate = useNavigate();
  const [key, setKey] = useState(10);
  const [showContactModal, setShowContactModal] = useState(false);

  useEffect(() => {
    document.title =
      "Kafood | Phần mềm quản lý suất ăn và định lượng dinh dưỡng";
  }, []);

  const dataFunction = [
    {
      parent: "Thực đơn.",
      src: "assets/user/images/product/detail/2.png",
      id: 0,
      check: 1,
      child: [
        { label: "Lên thực đơn hàng tuần, hằng ngày nhanh chóng." },
        { label: "In định lượng cho bếp theo món ăn và nhóm trẻ." },
        { label: "Xuất file làm sổ thực đơn tuần chi tiết." },
        { label: "Lên dự kiến phiếu đi chợ hằng ngày." },
      ],
    },
    {
      parent: "Cân đối khẩu phần dinh dưỡng theo buổi ăn và nhóm trẻ.",
      id: 1,
      check: 1,
      src: "assets/user/images/product/detail/1_1.png",
      child: [
        {
          label:
            "Xác định năng lượng và thành phần dinh dưỡng (Protein, Lipid, gluxit).",
        },
        {
          label:
            "Cân đối khẩu phần ăn theo khuyến nghị viện dinh dưỡng, tính thu chi chênh lệch tiền ăn.",
        },
        {
          label:
            "Xuất file báo cáo hóa đơn đi chợ, kết quả dưỡng chất, và thiết lập dưỡng chất hằng ngày.",
        },
      ],
    },
    {
      parent: "Định lượng món ăn.",
      id: 2,
      check: 1,
      // src: "assets/user/images/product/web/dinhluong.png",
      src: "assets/user/images/product/detail/4_4.png",
      child: [
        { label: "Món ăn định lượng theo nhóm học sinh và nhân viên." },
        { label: "Ngân hàng có hàng nghìn món ăn cho trường tham khảo." },
      ],
    },
    {
      parent: "Biểu mẫu báo cáo theo qui định.",
      id: 3,
      check: 1,
      // src: "assets/user/images/product/web/bieumau.png",
      src: "assets/user/images/product/detail/3_3.png",
      child: [
        { label: "Sổ kiểm thực 3 bước." },
        { label: "Sổ lưu mẫu ngày." },
        { label: "Sổ tài chính công khai." },
        { label: "Sổ thực đơn tuần." },
        { label: "Phiếu xuất nhập kho." },
        { label: "Sổ dinh dưỡng tuần/ tháng." },
      ],
    },

    {
      parent: "Quản lý suất ăn học sinh.",
      id: 4,
      check: 1,
      src: "assets/user/images/product/detail/5_5.png",
      child: [
        { label: "Báo và cắt suất ăn học sinh chính xác, nhanh." },
        { label: "Thống kê sỉ số học sinh, hằng ngày." },
        {
          label: "Tính số tiền ăn trả lại cho phụ huynh khi học sinh nghỉ học.",
        },
        { label: "Lưu lịch sử báo cắt suất ăn của giáo viên." },
      ],
    },
    {
      parent: "Quản lý kho hàng và xuất nhập tồn.",
      id: 5,
      check: 1,
      // src: "assets/user/images/product/web/kho.png",
      src: "assets/user/images/product/detail/6_6.png",
      child: [
        { label: "Phân loại nguyên vật liệu theo hàng chợ, hàng kho, vật tư." },
        { label: "Quản lý xuất nhập tồn theo lô và hạn sử dụng." },
        { label: "Cảnh báo hàng hết hạn, lưu lại lịch sử xuất nhập kho." },
        {
          label:
            "Có ngân hàng gần 600 nguyên liệu đầy đủ thành phần năng lượng theo viện dinh dưỡng, bộ y tế.",
        },
      ],
    },
    {
      parent: "Nhà cung cấp.",
      id: 6,
      check: 1,
      // src: "assets/user/images/product/web/nhacungcap.png",
      src: "assets/user/images/product/detail/7_7.png",
      child: [
        { label: "Danh sách nhà cung cấp và lịch sử nhập hàng." },
        {
          label:
            "Kiểm tra giá cả lên xuống theo mặt hàng và so sánh giá của các nhà cung cấp khác nhau.",
        },
        { label: "Báo cáo chi phí mua hàng theo nhà cung cấp." },
      ],
    },

    {
      parent: "Quản lý nhân viên và phân quyền",
      id: 7,
      check: 1,
      // src: "assets/user/images/product/web/nhanvien.png",
      src: "assets/user/images/product/detail/8_8.png",
      child: [
        {
          label:
            "Danh sách nhân viên, giáo viên theo chuẩn cơ sở dữ liệu quốc gia.",
        },
        { label: "Phân quyền nhân viên chi tiết theo chức vụ được giao." },
        { label: "Chấm cơm và chấm công cho nhân viên." },
        {
          label:
            "Cấp tài khoản riêng cho nhân viên cắt suất ăn học sinh và xin nghỉ phép.",
        },
      ],
    },
    {
      parent: "Quản lý danh sách học sinh.",
      id: 8,
      check: 1,
      // src: "assets/user/images/product/web/danhsachhocsinh.png",
      src: "assets/user/images/product/detail/9_9.png",
      child: [
        { label: "Danh sách học sinh theo chuẩn cơ sở dữ liêu quốc gia." },

        { label: "Chuyển lớp, sắp lớp cho học sinh." },
        {
          label: "Xuất file danh sách học sinh có ngày sinh nhật trong tháng.",
        },
        { label: "Xuất file báo cáo phổ cập giáo dục theo địa phương." },
      ],
    },

    {
      parent: "Báo cáo thống kê.",
      id: 9,
      check: 1,
      // src: "assets/user/images/product/web/baocao.png",
      src: "assets/user/images/product/web/taichinh.png",
      child: [
        { label: "Báo cáo chi tiết sỉ số/ suất ăn." },
        { label: "Báo cáo ngày nghỉ nhân viên." },
        {
          label:
            "Báo cáo chi phí tiền ăn (hàng chợ, kho) và bán trú (hàng vật tư).",
        },
      ],
    },
    {
      id: 10,
      // src: "assets/user/images/product/web/10.png",
      src: "assets/user/images/product/web/tongquan.png",
    },
  ];
  const dataFeedback = [
    {
      school_name: "Mầm non quân khu 9",
      id: 0,
      src: "assets/user/images/product/feedback/quankhu9.jpg",

      represent: " Chị An",
      cmt: "Kafood là phần mềm quản lý đầy đủ chức năng, mang lại hiệu quả cao và đơn giản hơn trong quá trình quản lý",
    },
    {
      school_name: "Mầm non niềm tin",
      id: 1,
      src: "assets/user/images/product/feedback/niemtin.jpg",
      represent: " Anh Hào",
      cmt: "Kafood là phần mềm mà tôi nghĩ nó giải pháp tốt nhất cho đến thời điểm hiện tại dùng để quản lý các trường mầm non.",
    },
    {
      school_name: "Mần Non Hà Nguyễn",
      id: 3,
      src: "assets/user/images/product/feedback/hanguyen.jpg",
      represent: "Chị Hà",
      cmt: "Từ khi sử dụng phần mềm Kafood nhóm trẻ của tôi hoàn thành các báo cáo phòng sở, báo cáo sổ 3 bước rất nhanh, có thời gian để tập trung chăm sóc trẻ hơn.",
    },
    {
      school_name: "Mầm Non Tre Việt",
      id: 4,
      src: "assets/user/images/product/feedback/treviet.jpg",
      represent: "Tre Việt",
      cmt: "Kafood có ngân hàng món ăn rất nhiều món, tôi không còn đau đầu suy nghĩ món ăn lên thực đơn mỗi tuần.",
    },
  ];

  const sendSDT = async (e) => {
    if (formSDT.current.phone.value.toString().length > 9) {
      const success = await handleSaveCustomer();
      if (success) {
        navigate("/dang-ky-thanh-cong");
        toast(`Đăng ký thành công`, {
          type: "success",
          autoClose: 1000,
          style: { marginTop: "50px" },
        });
      } else {
        toast(`Đăng ký thất bại`, {
          type: "error",
          autoClose: 1000,
          style: { marginTop: "50px" },
        });
      }
    } else {
      toast(`Vui lòng nhập số điện thoại`, {
        type: "error",
        autoClose: 1000,
        style: { marginTop: "50px" },
      });
    }
  };

  const handleSaveCustomer = async () => {
    // const fullName = formSDT.current.user_name.value.toString();
    const phone = formSDT.current.phone.value.toString();
    // const message = formSDT.current.message.value.toString();

    const data = {
      // fullName: fullName,
      phone: phone,
      // message: message,
      slug: slug,
    };

    try {
      const res = await axios.post(`/customer/create`, data);
      if (res.data.errCode === 0) {
        return true;
      }
    } catch (error) {
      toast(`Có lỗi xảy ra`, {
        type: "error",
        autoClose: 1000,
        style: { marginTop: "50px" },
      });
    }
    return false;
  };

  const formSDT = useRef();
  // const sendSDT = (e) => {
  //   if (formSDT.current.phone.value.length > 9) {
  //     emailjs
  //       .sendForm(
  //         "service_fihg5vx",
  //         "template_es4vjjf",
  //         formSDT.current,
  //         "tt8EBINZkDg6uPMLi"
  //       )
  //       .then(
  //         () => {
  //           formSDT.current.phone.value = "";
  //           navigate("/dang-ky-thanh-cong");
  //           toast(`Đăng ký thành công`, {
  //             type: "success",
  //             autoClose: 1000,
  //             style: { marginTop: "50px" },
  //           });
  //         },
  //         (error) => {
  //           console.log(error.text);
  //         }
  //       );
  //   } else {
  //     toast(`Vui lòng nhập số điện thoại`, {
  //       type: "error",
  //       autoClose: 1000,
  //       style: { marginTop: "50px" },
  //     });
  //   }
  // };

  const meta = {
    title: "Kafood | Phần mềm quản lý suất ăn và định lượng dinh dưỡng",
    description:
      "Kafood - Phần mềm quản lý suất ăn và định lượng dinh dưỡng trẻ mầm non. ",
    keywords:
      "Kafood, Phần mềm quản lý suất ăn, định lượng dinh dưỡng trẻ mầm non",
    og_image:
      process.env.REACT_APP_FRONTEND_URL +
      "/assets/user/images/product/detail/5_5.png",
    og_image_type: "image/png",
    // fb_app_id: '',
  };

  return (
    <div id="page_home">
      <HeadMeta {...meta} />

      {showContactModal ? (
        <ContactModal
          show={showContactModal}
          onHide={() => setShowContactModal(false)}
        />
      ) : null}

      <div className="page-content-product">
        <div className="main-product">
          <div className="find-box">
            <h1 className="text-center fw-bold text-primary">
              PHẦN MỀM QUẢN LÝ SUẤT ĂN <br />
              VÀ ĐỊNH LƯỢNG DINH DƯỠNG
            </h1>
            <div className="product-sh">
              <Form ref={formSDT} className="form_input_phone">
                <Row className="m-0 justify-content-center">
                  <Col lg={4} md={6}>
                    <InputGroup className="mb-3">
                      <Form.Control
                        placeholder="Nhập số điện thoại..."
                        name="phone"
                      />
                      <pre> </pre>
                      <Button variant="primary" onClick={() => sendSDT()}>
                        Đăng ký tư vấn
                      </Button>
                    </InputGroup>
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
        </div>
      </div>

      <div className="cat-main-box bg-white">
        <div className="container py-4">
          <div className="row panel-row">
            <div className="function">
              <div className="title_home">
                <div className="text_title">
                  <h2>Giải pháp chuyển đổi số cho trường học</h2>
                </div>
              </div>
              <FunctionHome />
            </div>
          </div>

          <center>
            <Button
              onClick={() => setShowContactModal(true)}
              className="button-71"
            >
              Đăng ký dùng thử{" "}
            </Button>
          </center>
        </div>
      </div>

      <div className="title_home">
        <Cost />
      </div>

      <div className="container ">
        <Row className="justify-content-md-center">
          <Col md={6} className="mb-3">
            <div className="title_home">
              <div className="text_title">
                <h2>GIỚI THIỆU KAFOOD</h2>
              </div>
            </div>

            <p style={{ lineHeight: "1.5rem" }}>
              Phần mềm quản lý suất ăn giúp quản lý các thông tin về suất ăn của
              trẻ trong trường mầm non. Các thông tin này gồm số lượng suất ăn,
              thực đơn, định lượng dinh dưỡng và các thông tin liên quan đến
              trường học.
            </p>

            <center>
              <Button
                onClick={() => setShowContactModal(true)}
                className="button-71"
              >
                Đăng ký tư vấn{" "}
              </Button>
            </center>
          </Col>

          <Col md={6} className="mb-3">
            <div className="title_home">
              <div className="text_title">
                <h2>PHẢN HỒI CỦA CÁC TRƯỜNG MẦM NON</h2>
              </div>
            </div>
            <iframe
              width="100%"
              height="300px"
              src="https://www.youtube.com/embed/4DCQxO-UfzM?si=nD0YVaUW-8FdLO7y"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          </Col>
        </Row>
      </div>

      <div className="container">
        <div className="title_home">
          <div className="text_title">
            <h2>PHẢN HỒI CỦA KHÁCH HÀNG</h2>
          </div>
        </div>

        <center>
          <div className="row">
            {dataFeedback.map((item, i) => {
              return (
                <div className="col-md-6 col-sm-4 " key={`dataFeedback${i}`}>
                  <div className="feedback">
                    <div className="title "> {item.school_name}</div>
                    <div className="img_">
                      <img src={item.src} className="rounded-circle" alt="" />
                      {/* <img src='assets/user/images/product/web/baocao.png'/> */}
                    </div>
                    <div className="text">{item.represent}</div>
                    <p> {item.cmt}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </center>
      </div>

      <div className="container">
        <div className="title_home">
          <div className="text_title">
            <h2>ĐỐI TÁC</h2>
          </div>
          <div className="text-center">
            Kafood luôn tận tâm đồng hành, xây dựng liên kết bền vững với Khách
            hàng và Đối tác.
          </div>
          <Row className="partner">
            <Col md={3} sm={6} xs={6} className="partner-item">
              <Link
                to="https://www.vietinbank.vn/"
                target="_blank"
                title="VietinBank Cần Thơ"
              >
                <img
                  className="partner-img"
                  src="assets/images/partner/vietin-bank.png"
                  width="100%"
                  alt="Ngân hàng VietinBank Cần Thơ"
                />
              </Link>
            </Col>
            <Col md={3} sm={6} xs={6} className="partner-item">
              <Link
                to="https://viettel.cantho.vn/"
                target="_blank"
                title="Viettel Cần Thơ"
              >
                <img
                  className="partner-img"
                  src="assets/images/partner/viettel-can-tho.png"
                  width="100%"
                  alt="Viettel Cần Thơ"
                />
              </Link>
            </Col>
            <Col md={3} sm={6} xs={6} className="partner-item">
              <Link
                to="https://vbict.vn/"
                target="_blank"
                title="Bảo hiểm VietinBank Cần Thơ"
              >
                <img
                  className="partner-img"
                  src="assets/images/partner/vbi-can-tho.png"
                  width="100%"
                  alt="Bảo hiểm VietinBank Cần Thơ"
                />
              </Link>
            </Col>
            <Col md={3} sm={6} xs={6} className="partner-item">
              <Link to="https://hdbank.com.vn/" title="HDBank Cần Thơ">
                <img
                  className="partner-img"
                  src="assets/images/partner/hdbank.png"
                  width="100%"
                  alt="Ngân hàng HDBank Cần Thơ"
                />
              </Link>
            </Col>
            <Col md={3}></Col>
          </Row>
        </div>
      </div>
    </div>
  );
}

export default HomeIndex;
